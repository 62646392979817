// 封装公共下载表格方法
export const downloadFile = (data,fileName) => {// data 返回的文件流 fileName 下载的文件名
    const blob = new Blob([data])
    if ('download' in document.createElement('a')) {
        const link = document.createElement('a')
        link.download = fileName
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        document.body.appendChild(link)
        link.click()
        URL.revokeObjectURL(link.href)
        document.body.removeChild(link)
    } else {
        navigator.msSaveBlob(blob, fileName)
    }
}
